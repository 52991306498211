import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import { useWindowSize } from "react-use";
import ReactSwitch from "react-switch";

import Layout from "../components/layout";
import Page from "../components/page";

const PlainPage = ({ data }: any) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { markdownRemark } = data;
  const {
    html,
    frontmatter: { title, description },
  }: { html: string; frontmatter: { title: string; description: string } } =
    markdownRemark;

  const { width } = useWindowSize();

  const [isTransformed, setIsTransformed] = useState(false);

  return (
    <Layout title={title} metaDescription={description}>
      <div style={{ position: "absolute", right: 0, top: "1em" }}>
        <label style={{ display: "flex" }}>
          <span style={{ marginRight: "1em" }}>Transform with Summerbell</span>
          <ReactSwitch
            checked={isTransformed}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#1c73b5"
            onChange={() => {
              setIsTransformed(!isTransformed);
            }}
          />
        </label>
      </div>
      <div
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: html }}
        className={isTransformed ? "d-none" : "d-block"}
      />
      <Page
        width={Math.min(width - 50, 1000)}
        children={contentRef.current?.children}
        className={isTransformed ? "d-block" : "d-none"}
      />
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default PlainPage;
