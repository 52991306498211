import React, { useContext } from "react";

import { AppContext } from "./app-context";
import ArcText from "./arc-text";

type Props = {
  children?: HTMLCollection;
  fontSize?: number;
  className?: string;
  width?: number;
};

const Page = ({ children, fontSize, className, width = 400 }: Props) => {
  const { userOptions } = useContext(AppContext);

  const font = fontSize ?? userOptions?.fontSize ?? 18;

  const wrappedChildren =
    children &&
    Array.from(children).map((child) => {
      let childFont = font;

      switch (child.tagName) {
        case "H1":
          childFont *= 2;
          break;
        case "H2":
          childFont *= 1.5;
          break;
      }

      if (child.textContent) {
        return (
          <ArcText width={width} options={{ fontSize: childFont }}>
            {child.textContent}
          </ArcText>
        );
      }

      if (child.children) {
        return (
          <Page fontSize={childFont} width={width} children={child.children} />
        );
      }

      return child;
    });

  return <span className={className}>{wrappedChildren}</span>;
};

export default Page;
